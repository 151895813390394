var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "dashboard px-0 grey lighten-1", attrs: { fluid: "" } },
    [
      _c("div", { staticClass: "text-center" }, [
        _vm.isAuthenticated && _vm.user
          ? _c("div", { staticClass: "pa-3 d-flex align-center flex-column" }, [
              _c(
                "div",
                { staticClass: "user-card", on: { click: _vm.showDetails } },
                [
                  _c("div", { staticClass: "px-3 pb-1 d-flex flex-column" }, [
                    _c("div", { staticClass: "card-user" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.user.firstName) +
                          " " +
                          _vm._s(_vm.user.lastName) +
                          " "
                      )
                    ]),
                    _c("div", { staticClass: "card-number" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("dashboard.cardNumber")) + " "
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.user.fidelityCard))])
                    ])
                  ])
                ]
              ),
              _c("div", [_vm._v(_vm._s(_vm.$t("dashboard.showBarcode")))])
            ])
          : _c(
              "div",
              { staticClass: "px-3 pb-3" },
              [
                _c("img", {
                  staticClass: "card-img my-3",
                  attrs: { src: "/img_layout/GALA_club_fronte_not-logged.svg" }
                }),
                _c("div", { staticClass: "mb-3 text-body-0" }, [
                  _vm._v(_vm._s(_vm.$t("dashboard.subtitle")))
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "px-sm-12",
                    attrs: {
                      block: _vm.$vuetify.breakpoint.xsOnly,
                      depressed: "",
                      rounded: "",
                      color: "primary",
                      large: ""
                    },
                    on: { click: _vm.doLoginDashboard }
                  },
                  [_vm._v(_vm._s(_vm.$t("dashboard.login")))]
                )
              ],
              1
            )
      ]),
      _c(
        "div",
        [
          _vm._l(_vm.menuSections, function(section, index) {
            return _c(
              "v-list",
              {
                key: "section-" + index,
                staticClass: "custom-list",
                attrs: { dense: "", flat: "" }
              },
              [
                _c("v-subheader", [_vm._v(_vm._s(_vm.$t(section.title)))]),
                _c(
                  "v-list-item-group",
                  { attrs: { color: "primary" } },
                  _vm._l(section.items, function(item, i) {
                    return _c(
                      "v-list-item",
                      {
                        key: "item-" + i,
                        on: {
                          click: function($event) {
                            return _vm.handleLogin(item.routePath)
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [
                            _c("v-icon", {
                              attrs: { color: "primary" },
                              domProps: { textContent: _vm._s("$" + item.icon) }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(_vm.$t(item.title)))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          }),
          _vm.isAuthenticated
            ? _c(
                "v-list",
                {
                  staticClass: "custom-list py-0",
                  attrs: { dense: "", flat: "" }
                },
                [
                  _c(
                    "v-list-item-group",
                    { attrs: { color: "error" } },
                    [
                      _c(
                        "v-list-item",
                        { on: { click: _vm.doLogout } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", {
                                attrs: { color: "error" },
                                domProps: { textContent: _vm._s("$logout") }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "error--text" },
                                [_vm._v(_vm._s(_vm.$t("dashboard.logout")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "i18n",
        {
          staticClass: "my-2 text-center",
          attrs: { path: "footer.version", tag: "div" }
        },
        [_vm._v(" " + _vm._s(_vm.version) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }